
import { CashFlowAPAR } from 'app/api/core/types';
import { ReportsType } from 'app/api/reports/types';

const yyyymmddFormatter = (params: any) => {
    if (!params.value) return params.value;
    const parsedDate = typeof params.value === 'string' ? new Date(params.value) : params.value;

    // Check if the parsedDate is a valid date
    if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date');
    }

    // Get year, month, and day from parsedDate
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2); // Months are zero based
    const day = ('0' + parsedDate.getDate()).slice(-2);

    // Return formatted date in yyyy-MM-dd format
    return `${year}-${month}-${day}`;
};

const formatNumber = (params: any) => {
    // Format the number to 2 decimal places
    const fixedValue = parseFloat(params.value).toFixed(2);

    // Split into integer and decimal parts
    const parts = fixedValue.split('.');

    // Add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};

const currencyFormatter = (params: any) => {
    if (!params.value) return;

    return '$' + formatNumber(params);
};

const valueFormatter: any = {
    DUE_DATE: yyyymmddFormatter,
    PAYMENT_DATE: yyyymmddFormatter,
    TR_INVOICE_DATE: yyyymmddFormatter,
    AMOUNT_DUE: currencyFormatter
};

const useCreateCashFlowAPARColumns = (sheetData: CashFlowAPAR, report: ReportsType) => {
    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        const reportReadonly = (report.readOnly || []);
        const reportHidden = (report.hidden || []);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            type: name === 'AMOUNT_DUE' ? 'rightAligned' : undefined,
            valueFormatter: valueFormatter[name] ? valueFormatter[name] : undefined,
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name]
        });
    });

    return createdColumns;
};

export default useCreateCashFlowAPARColumns;
