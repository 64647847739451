
import { NZOrders } from 'app/api/core/types';
import { ReportsType } from 'app/api/reports/types';

const useCreateNZOrdersColumns = (sheetData: NZOrders, report: ReportsType) => {
    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        const reportReadonly = (report.readOnly || []);
        const reportHidden = (report.hidden || []);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name]
        });
    });

    return createdColumns;
};

export default useCreateNZOrdersColumns;
