import { useEffect, useMemo, useState } from 'react';
import { FilterChangedEvent } from 'ag-grid-community';
import AppButton from 'app/components/Button/AppButton';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { useGetMemberPaymentsData } from 'app/api/aaan/aaan';
import { MemberPayments } from 'app/api/aaan/types';
import useCreateAAANColumns from '../useCreateAAANColumns';
import { updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useSpreadsheet from '../../hooks/useSpreadsheet';
import useUpdateRows from './hooks/useUpdateRows';

import '../spreadsheet.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetMemberPayments = ({ report, className }: Props) => {
    const [pinnedTotal, setPinnedTotal] = useState<[
        {
            AIRPORT_NAME: string,
            BILLING_AMOUNT: number,
            BILLING_MOVEMENTS: number
        }
    ]>();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetMemberPaymentsData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false

        });

    const { gridApi, pageSize, isSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: MemberPayments[] = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const createdColumns = useCreateAAANColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    const { handleUpdateRows } = useUpdateRows(refetch);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport sheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const onFilterChanged = (params: FilterChangedEvent) => {
        const api = params.api;

        // Check if there are any active filters
        const filterModel = api.getFilterModel();
        const isFilterEmpty = Object.keys(filterModel).length === 0;

        if (isFilterEmpty) {
            // No filters applied - reset selections
            api.deselectAll();
        }

        let amountTotal = 0;
        let movementsTotal = 0;

        // Sum up the totals for all filtered rows
        api.forEachNodeAfterFilter((node) => {
            if (node.data) {
                amountTotal += node.data.BILLING_AMOUNT || 0;
                movementsTotal += node.data.BILLING_MOVEMENTS || 0;
            }
        });

        setPinnedTotal([
            {
                AIRPORT_NAME: 'Totals',
                BILLING_AMOUNT: parseFloat(amountTotal.toFixed(2)),
                BILLING_MOVEMENTS: movementsTotal
            }
        ]);
    };

    const onSelectionChanged = () => {
        if (!gridApi) return;

        let amountTotal = 0;
        let movementsTotal = 0;

        const selectedRows = gridApi.getSelectedRows();

        if (selectedRows.length > 0) {
            selectedRows.forEach((node) => {
                amountTotal += node.BILLING_AMOUNT;
                movementsTotal += node.BILLING_MOVEMENTS;
            });
        } else {
            gridApi.forEachNodeAfterFilter((node) => {
                amountTotal += node.data.BILLING_AMOUNT || 0;
                movementsTotal += node.data.BILLING_MOVEMENTS || 0;
            });
        }

        setPinnedTotal([
            {
                AIRPORT_NAME: 'Totals',
                BILLING_AMOUNT: parseFloat(amountTotal.toFixed(2)),
                BILLING_MOVEMENTS: movementsTotal
            }
        ]);
    };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3'>
                        <AppButton size='sm'
                            onClick={() => handleUpdateRows(gridApi ? gridApi.getSelectedRows() : [])}>Process Payments</AppButton>
                    </div>
                    <div className='d-flex justify-content-end gap-3' />
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        sheetData={sheetData}
                        pinnedTotal={pinnedTotal}
                        handleGridReady={handleGridReady}
                        onFilterChanged={onFilterChanged}
                        onSelectionChanged={onSelectionChanged}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetMemberPayments;
