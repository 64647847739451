import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { CashFlowAPAR, CoreType, LoadList, LoadListRequest, NZOrders } from './types';

const base = 'core';

// Production Planning
const GET_CORE_PRODUCTION_PLANNING = 'getCoreProductionPlanning';

export function useGetCoreData(
    filterType: 'machine' | 'drying' | 'treatmentplant',
    { ...options }: UseApiRequestOptions<any> = {}
) {
    return useReactQueryRequest<CoreType[]>(
        [`${GET_CORE_PRODUCTION_PLANNING}-${filterType}`],
        `/${base}/spreadsheet?filterType=${filterType}`,
        { ...options, method: 'GET' }
    );
}

export async function updateCoreData(data: CoreType[], queryParams: { [key: string]: string }) {
    return await makeApiRequest<any>(
        `/${base}/spreadsheet/update`,
        'POST',
        data,
        'application/json',
        queryParams
    );
}

// Load list
const GET_CORE_LOAD_LIST = 'getCoreLoadList';

export function useGetLoadListData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<LoadList[]>(
        [GET_CORE_LOAD_LIST],
        `/${base}/spreadsheet/load-list`,
        { ...options, method: 'GET' }
    );
}

export async function updateLoadListData(data: LoadListRequest[]) {
    return await makeApiRequest<any>(
        `/${base}/spreadsheet/load-list/update`,
        'POST',
        data,
        'application/json'
    );
}

// Cash Flow AP and AR
const GET_CASH_FLOW_AP_AR = 'getCashFlowAPAR';

export function useGetCashFlowAPARData(currencyFilter: 'USD' | 'NZD' | 'AUD', { ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<CashFlowAPAR[]>(
        [`${GET_CASH_FLOW_AP_AR}-${currencyFilter}`],
        `/${base}/spreadsheet/cash-flow-ap-ar?currency=${currencyFilter}`,
        { ...options, method: 'GET' }
    );
}

const GET_CASH_FLOW_AP_AR_INVOICE_STATUS_OPTIONS = 'getCashFlowAPARInvoiceStatusOptions';

export function useGetCashFlowAPARStatusOptionsData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<Record<string, string>>(
        [GET_CASH_FLOW_AP_AR_INVOICE_STATUS_OPTIONS],
        `/${base}/spreadsheet/cash-flow-ap-ar/invoice-options`,
        { ...options, method: 'GET' }
    );
}

export async function updateLiveCashFlowData(data: CashFlowAPAR[]) {
    return await makeApiRequest<any>(
        `/${base}/spreadsheet/cash-flow-ap-ar/update`,
        'POST',
        data,
        'application/json'
    );
}

// NZ Orders
const GET_NZ_ORDERS = 'getNZOrders';

export function useGetNZOrdersData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<NZOrders[]>(
        [GET_NZ_ORDERS],
        `/${base}/spreadsheet/nz-orders`,
        { ...options, method: 'GET' }
    );
}

export async function updateNZOrdersData(data: NZOrders[]) {
    return await makeApiRequest<any>(
        `/${base}/spreadsheet/nz-orders/update`,
        'POST',
        data,
        'application/json'
    );
}
