import React from 'react';
import Form from 'react-bootstrap/Form';
import AppSelect from 'app/components/Select/Select';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import AppInput from 'app/components/AppInput/AppInput';
import { useForm, Controller, SubmitHandler, useFieldArray } from 'react-hook-form';
import { SingleValue } from 'react-select';
import Typography from 'app/components/Typography';
import { CashFlowAPAR } from 'app/api/core/types';
import AppDatePicker from 'app/components/AppDatePicker';
import moment from 'moment';
import { updateLiveCashFlowData, useGetCashFlowAPARStatusOptionsData } from 'app/api/core/core';

interface UpdateModalProps {
    refetch: any;
    selectedRows: CashFlowAPAR[];
}

type Field = {
    label: string;
    value: string;
};

type FormValues = {
    updateFields: {
        fieldToUpdate: Field;
    }[];
    STATUS_CODE_VALUE: { label: string; value: string };
    PAYMENT_DATE_VALUE?: string;
    NOTES_VALUE?: string;
};

const fieldsToUpdate: Field[] = [
    { label: 'Payment Date', value: 'PAYMENT_DATE' },
    { label: 'Status Code', value: 'STATUS_CODE' },
    { label: 'Notes', value: 'NOTES' }
];

const UpdateModal: React.FC<UpdateModalProps> = ({ selectedRows, refetch }) => {
    const { handleSubmit, control, watch, formState: { isSubmitting } } = useForm<FormValues>({
        defaultValues: {
            updateFields: [{ fieldToUpdate: fieldsToUpdate[0] }],
            STATUS_CODE_VALUE: { label: '', value: '' },
            PAYMENT_DATE_VALUE: '',
            NOTES_VALUE: ''
        }
    });

    const { data: invoiceStatusData } = useGetCashFlowAPARStatusOptionsData();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'updateFields'
    });

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        // Update the selected rows
        const updatedSelectedRows = selectedRows.map(rowData => {
            let updatedRowData = { ...rowData };

            // // Loop through the updateFields array
            data.updateFields.forEach(updateField => {
                const fieldToUpdate = updateField.fieldToUpdate.value as 'NOTES' | 'PAYMENT_DATE' | 'STATUS_CODE';

                if (fieldToUpdate === 'STATUS_CODE') {
                    updatedRowData = { ...updatedRowData, STATUS_CODE: data.STATUS_CODE_VALUE.value, INVOICE_STATUS: invoiceStatusData?.data.data[data.STATUS_CODE_VALUE.value] || '' };
                } else if (fieldToUpdate === 'NOTES') {
                    updatedRowData = { ...updatedRowData, NOTES: data.NOTES_VALUE || '' };
                } else {
                    updatedRowData = { ...updatedRowData, PAYMENT_DATE: data.PAYMENT_DATE_VALUE || '' };
                }
            });

            return updatedRowData;
        });

        try {
            await updateLiveCashFlowData(updatedSelectedRows);

            await refetch();
            setNotification('Field updated', 'success');
        } catch (error) {
            setNotification('Error updating field', 'error');
        } finally {
            clearModalContent();
        }
    };

    const selectedFieldValues = watch('updateFields').map(field => field.fieldToUpdate.value);

    return <form onSubmit={handleSubmit(onSubmit)}>
        {
            selectedRows.length > 0
                ? <>
                    <Typography className='mb-5'>Selected row{selectedRows.length > 1 ? 's' : ''}: {selectedRows.length}</Typography>
                    {fields.map((item, index) => {
                        const availableFields = fieldsToUpdate.filter(field => !selectedFieldValues.includes(field.value));

                        return <div key={item.id} className={`mb-4 ${fields.length > 0 && index > 0 ? 'border-top border-secondary' : ''} py-4`}>
                            <Form.Label>Select field to update</Form.Label>
                            <Controller
                                name={`updateFields.${index}.fieldToUpdate`}
                                control={control}
                                render={({ field }) => (
                                    <AppSelect
                                        {...field}
                                        className='mb-4'
                                        options={availableFields}
                                        onChange={(val: SingleValue<Field>) => {
                                            if (val) {
                                                field.onChange(val);
                                            }
                                        }} />
                                )} />

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'NOTES' && (
                                <Controller
                                    name='NOTES_VALUE'
                                    control={control}
                                    render={({ field }) => (
                                        <AppInput
                                            className='mb-4'
                                            label='Notes'
                                            value={field.value}
                                            name='NOTES_VALUE'
                                            onChange={(val) => {
                                                field.onChange(val); // Only update the value if it matches the regex
                                            }} />
                                    )} />
                            )}

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'STATUS_CODE' && (
                                <Controller
                                    name='STATUS_CODE_VALUE'
                                    control={control}
                                    render={({ field }) => (
                                        <AppSelect
                                            {...field}
                                            className='mb-4'
                                            placeholder='Select Machine'
                                            options={invoiceStatusData ? Object.keys(invoiceStatusData.data.data).map((data) => ({ label: data, value: data })) : []}
                                            onChange={(val: SingleValue<Field>) => {
                                                if (val) {
                                                    field.onChange(val);
                                                }
                                            }} />
                                    )} />
                            )}

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'PAYMENT_DATE' && (
                                <Controller
                                    name='PAYMENT_DATE_VALUE'
                                    control={control}
                                    render={({ field }) => (
                                        <AppDatePicker
                                            dateFormat='yyyy/MM/dd'
                                            // showCustomHeader={true}
                                            showIcon={true}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            icon='fa fa-calendar'
                                            className='w-100 mb-4'
                                            isClearable={true}
                                            placeholderText='Select Payment Date'
                                            onChange={(val) => {
                                                // Format the date to dd/MM/yyyy and convert to string
                                                const formattedDate = val ? moment(val).format('yyyy-MM-DD') : '';

                                                field.onChange(formattedDate); // Call field.onChange with the formatted string
                                            }}
                                            selected={field.value ? moment(field.value, 'yyyy/MM/DD').toDate() : null} />
                                    )} />
                            )}

                            {fields.length > 1 && <Button variant='danger' size='sm' onClick={() => remove(index)}>Remove</Button>}
                        </div>;
                    })}
                    <Button disabled={fields.length === fieldsToUpdate.length} variant='secondary' size='sm'
                        onClick={() => append({ fieldToUpdate: { label: '', value: '' } })}>Add Another Field</Button>
                </>
                : <Typography className='mb-5'>Please select rows to update.</Typography>
        }
        <div className='w-100 d-flex justify-content-end gap-3'>
            <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
            {selectedRows.length > 0 && <Button disabled={isSubmitting} isLoading={isSubmitting} type='submit'
                size='sm'>Submit</Button>}
        </div>
    </form>;
};

const useUpdateRows = (refetch: any) => {
    const { setModalContent } = useModalStore();

    const handleUpdateRows = (selectedRows: CashFlowAPAR[]) => {
        setModalContent({
            content: <UpdateModal
                selectedRows={selectedRows}
                refetch={refetch} />,
            title: 'Update Field'
        });
    };

    return { handleUpdateRows };
};

export default useUpdateRows;
