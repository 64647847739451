import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { CashFlowAPAR } from 'app/api/core/types';
import { useGetCashFlowAPARStatusOptionsData } from 'app/api/core/core';

type Props = {
    pageSize: string;
    parsedRawSheetData: CashFlowAPAR[];
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
    onRowSelect: () => void;
}

const PINNED_LEFT = ['TRANS_TYPE', 'ACTION_ID', 'TRANS_REF', 'REP_CODE'];
const EDITABLE_FIELDS = ['STATUS_CODE', 'PAYMENT_DATE', 'NOTES'];

const AgGrid = ({ pageSize, parsedRawSheetData, columnDefs, handleGridReady, onCellValueChanged, onRowSelect }: Props) => {
    const { data: invoiceStatusData } = useGetCashFlowAPARStatusOptionsData();

    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        columnDefs.forEach((item, index) => {
            if (EDITABLE_FIELDS.includes(item.field)) {
                updatedData[index] = {
                    ...updatedData[index],
                    editable: (params: any) => params.data.TRANS_REF && params.data.PAY_DESC
                };
            }

            if (item.field === 'STATUS_CODE' && invoiceStatusData) {
                updatedData[index] = {
                    ...updatedData[index],
                    cellEditorParams: {
                        values: Object.keys(invoiceStatusData.data.data)
                    },
                    valueSetter: (params: any) => {
                        const newValue = params.newValue;
                        const data = params.data;

                        data.STATUS_CODE = newValue;

                        data.INVOICE_STATUS = invoiceStatusData.data.data[newValue];

                        return true;
                    }
                };
            }

            if (item.field === 'TRANS_TYPE') {
                updatedData[index] = {
                    ...updatedData[index],
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true
                };
            }

            if (PINNED_LEFT.includes(item.field)) {
                updatedData[index] = {
                    ...updatedData[index],
                    pinned: 'left'
                };
            }
        });

        return updatedData;
    };

    const isRowSelectable = (node: any) => {
        return node.data.TRANS_REF && node.data.PAY_DESC;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            isRowSelectable={isRowSelectable}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={parsedRawSheetData}
            columnDefs={updateCustomComponents()}
            onRowSelected={onRowSelect} />
    );
};

export default React.memo(AgGrid);
