import React, { useMemo } from 'react';
import { GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { MemberPayments } from 'app/api/aaan/types';

type Props = {
    pageSize: string;
    sheetData: MemberPayments[];
    columnDefs: { [key: string]: any }[];
    pinnedTotal: [
        {
            AIRPORT_NAME: string,
            BILLING_AMOUNT: number,
            BILLING_MOVEMENTS: number
        }
    ] | undefined;
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
    onSelectionChanged: () => void;
    onFilterChanged: (params: any) => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, pinnedTotal, handleGridReady, onCellValueChanged, onFilterChanged, onSelectionChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        columnDefs.forEach((item, index) => {
            if (item.field === 'BATCH_NAME') {
                updatedData[index] = {
                    ...updatedData[index],
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true
                };
            }
        });

        return updatedData;
    };

    const isRowSelectable = (node: any) => {
        return node.data.XERO_CONTACT_VERIFIED;
    };

    const calculateTotals = () => {
        const amountTotal = sheetData.reduce((sum, row) => sum + row.BILLING_AMOUNT, 0);
        const movementsTotal = sheetData.reduce((sum, row) => sum + row.BILLING_MOVEMENTS, 0);

        return [
            {
                AIRPORT_NAME: 'Totals',
                BILLING_AMOUNT: parseFloat(amountTotal.toFixed(2)),
                BILLING_MOVEMENTS: movementsTotal
            }
        ];
    };

    const rowClassRules = {
        'aaan-light-grey': (params: RowClassParams) => params.data.XERO_EMAIL_VERIFIED === false
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            isRowSelectable={isRowSelectable}
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            rowClassRules={rowClassRules}
            pinnedBottomRowData={pinnedTotal || calculateTotals()}
            onFilterChanged={onFilterChanged}
            onSelectionChanged={onSelectionChanged}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
